import { Link, graphql, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import { FaFacebookSquare, FaBars } from 'react-icons/fa'
import Helmet from 'react-helmet'
import { trimStart } from 'lodash'
import uwrLogo from '../../images/logo_uwr_hi.png'
import ListLink from '../ListLink'
import Search from '../Search'

import './header.css'

class Header extends Component {
  constructor(props) {
    super(props)
    let fontSizeDefault = 'standard'
    if (typeof window !== 'undefined') {
      fontSizeDefault = localStorage.getItem('fontSize') || 'standard'
    }
    this.state = {
      fontSize: fontSizeDefault,
      fireSearch: this.props.fireSearch === 'true',
      searchQuery: '',
    }
  }

  componentDidMount() {
    const searchDecoded = decodeURIComponent(trimStart(window.location.search, '?='))
    if (searchDecoded !== '') {
      this.setState({ searchQuery: searchDecoded })
    }
  }

  componentDidUpdate() {
    const searchDecoded = decodeURIComponent(trimStart(window.location.search, '?='))
    if (searchDecoded !== '' && searchDecoded !== this.state.searchQuery) {
      this.setState({ searchQuery: searchDecoded })
    }
  }

  fontSizeSet = (size) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('fontSize', size)
    }
    this.setState({ fontSize: size })
  }

  render() {
    const { fontSize } = this.state

    return (
      <StaticQuery
        query={graphql`
          query {
            GetLanguages: allMarkdownRemark(
              sort: { order: ASC, fields: [frontmatter___language] }
              filter: { frontmatter: { templateKey: { eq: "language-post" } } }
            ) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    language
                  }
                }
              }
            }
            SearchIndexMain: allMarkdownRemark(
              filter: { frontmatter: { templateKey: { regex: "/^(?!.*(schedule-item|schedule-page)).*$/" } } }
            ) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    short_description
                    description
                    date
                    description_part_1
                    description_part_2
                    language
                    e_mail
                    phone_number_1
                    phone_number_2
                    address_line_1
                    address_line_2
                    image {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                    picture {
                      childImageSharp {
                        fluid(maxWidth: 800, quality: 70) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            SearchIndexSchedule: allMarkdownRemark(filter: { frontmatter: { templateKey: { regex: "/schedule-item|schedule-page/" } } }) {
              edges {
                node {
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    description
                    language
                    group
                    room
                    day
                    time
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <header className="header">
            <Helmet>
              <html class={fontSize} />
            </Helmet>
            <nav className="header__navbar">
              <div className="heaver__navbar--small">
                <FaBars className="header__navbar--toggle" onClick={this.props.drawerClickHandler} />
                <Search
                  className="header__navbar--search-mobile"
                  searchIndexMain={data.SearchIndexMain}
                  searchIndexSchedule={data.SearchIndexSchedule}
                  initialQuery={this.state.searchQuery}
                  fire={this.state.fireSearch}
                />
              </div>
              <h4 className="header__navbar--big">
                <a href="http://wfil.uni.wroc.pl/" className="header__navbar-link">
                  Wydział Filologiczny
                </a>
                <a href="http://www.isksio.uni.wroc.pl/" className="header__navbar-link">
                  ISKSIO
                </a>
                <a href="http://www.indologia.uni.wroc.pl/" className="header__navbar-link">
                  Indologia
                </a>
                <ul className="header__navbar-links">
                  <div className="header__navbar-dropdown">
                    <div className="header__navbar-dropdown--button">Jakich języków uczymy</div>
                    <div className="header__navbar-dropdown--content">
                      {data.GetLanguages.edges.map((edge) => (
                        <Link className="header__navbar-dropdown--link" key={edge.node.id} to={edge.node.fields.slug}>
                          {edge.node.frontmatter.language}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <ListLink key="news" to="/aktualnosci" className="header__navbar-links--regular">
                    Aktualności
                  </ListLink>

                  <ListLink key="schedule" to="/plan-zajec/" className="header__navbar-links--regular">
                    Plan zajęć
                  </ListLink>
                  <ListLink key="contact" to="/kontakt/" className="header__navbar-links--regular">
                    Kontakt
                  </ListLink>
                  <li key="fbHeader" className="header__navbar-links--icon">
                    <a
                      className="header__navbar-links--icon"
                      key="fbHeaderLink"
                      href="https://www.facebook.com/SzkolaJezykowAntycznychIOrientalnychUWr"
                    >
                      <FaFacebookSquare className="icon" />
                      <span />
                    </a>
                  </li>
                </ul>
                <Search
                  searchIndexMain={data.SearchIndexMain}
                  searchIndexSchedule={data.SearchIndexSchedule}
                  initialQuery={this.state.searchQuery}
                  fire={this.state.fireSearch}
                />
                <ul className="header__navbar-links header__navbar-fonts">
                  <button
                    key="standard"
                    type="button"
                    className={`header__navbar-links--button ${fontSize === 'standard' ? ' current' : ''}`}
                    onClick={() => this.fontSizeSet('standard')}
                  >
                    A
                  </button>
                  <button
                    key="large"
                    type="button"
                    className={`"header__navbar-links--button ${fontSize === 'large' ? ' current' : ''}`}
                    onClick={() => this.fontSizeSet('large')}
                  >
                    A<sup>+</sup>
                  </button>
                  <button
                    key="extra-large"
                    type="button"
                    className={`"header__navbar-links--button ${fontSize === 'extra-large' ? ' current' : ''}`}
                    onClick={() => this.fontSizeSet('extra-large')}
                  >
                    A<sup>++</sup>
                  </button>
                </ul>
              </h4>
            </nav>
            <div className="header__content">
              <Link className="header__content--title" to="/" dangerouslySetInnerHTML={{ __html: this.props.siteTitle }} />
              <a className="header__content-logo--link" href="https://uni.wroc.pl/">
                <img className="header__content-logo--img" src={uwrLogo} alt="UWr" />
              </a>
            </div>
            <div className="header__bottom">
              <svg viewBox="0 0 1440 160">
                <g>
                  <path fill="#fff" d="M0,160 L0,0 C549,0 823,158 1440,158 L1440,160 L0,160 Z" transform="matrix(-1 0 0 1 1440 0)" />
                </g>
              </svg>
            </div>
          </header>
        )}
      />
    )
  }
}

export default Header
