import { Link, graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { FaPhone, FaMap, FaEnvelope, FaFacebookSquare } from 'react-icons/fa'
import ListLink from '../ListLink'

import './footer.css'

const Footer = ({ data, siteTitle }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <footer className="footer">
      <div className="footer__top">
        <svg viewBox="0 0 1440 160">
          <g>
            <path fill="#fff" d="M0,0 L1440,0 C823,0 549,160 0,160 Z" />
          </g>
        </svg>
      </div>
      <div className="footer__content">
        <div className="footer__column--regular">
          <ul className="footer__column-links">
            <li key="siteTitle">
              <Link className="footer__column-links--regular" key="siteTitleLink" to="/" dangerouslySetInnerHTML={{ __html: siteTitle }} />
            </li>
            <li key="facultyLink">
              <a href="http://wfil.uni.wroc.pl/" className="footer__column-links--regular">
                Wydział Filologiczny
              </a>
            </li>
            <li key="instituteLink">
              <a href="http://www.isksio.uni.wroc.pl/" className="footer__column-links--regular">
                Instytut Studiów Klasycznych, Śródziemnomorskich i&nbsp;Orientalnych
              </a>
            </li>
            <li key="philologyLink">
              <a href="http://www.indologia.uni.wroc.pl/" className="footer__column-links--regular">
                Zakład Filologii Indyjskiej
              </a>
            </li>
            <li key="fbFooter">
              <a
                className="footer__column-links--regular"
                key="fbFooterLink"
                href="https://www.facebook.com/SzkolaJezykowAntycznychIOrientalnychUWr"
              >
                <FaFacebookSquare key="fbFooterIcon" className="footer__column-links--fb-icon" /> <span>Znajdź nas na Facebooku</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__column--small">
          <ul className="footer__column-links">
            <li key="quickLinksTitle" className="footer__column-links--top">
              Na skróty
            </li>
            <ListLink key="homeFooter" to="/" className="footer__column-links--regular">
              Strona główna
            </ListLink>
            <ListLink key="newsFooter" to="/aktualnosci/" className="footer__column-links--regular">
              Aktualności
            </ListLink>

            <ListLink key="scheduleFooter" to="/plan-zajec/" className="footer__column-links--regular">
              Plan zajęć
            </ListLink>
            <ListLink key="contactFooter" to="/kontakt/" className="footer__column-links--regular">
              Kontakt
            </ListLink>
          </ul>
        </div>
        <div className="footer__column--regular">
          <ul className="footer__column-links">
            <li key="ContactTitle" className="footer__column-links--top">
              Dane kontaktowe
            </li>
            <li key="address" className="footer__column-links--text">
              <FaMap key="addressIcon" className="footer__column-links--icon" />
              {frontmatter.address_line_1} <br />
              <p key="addressText" className="footer__column-links--contact-line">
                {frontmatter.address_line_2}
              </p>
            </li>
            <li key="phone" className="footer__column-links--text">
              <FaPhone key="phoneIcon" className="footer__column-links--icon" />
              {frontmatter.phone_number_1} <br />
              <p key="phoneText" className="footer__column-links--phone-line">
                {frontmatter.phone_number_2}
              </p>
            </li>
            <li key="mail">
              <FaEnvelope key="mailIcon" className="footer__column-links--mail-icon" />{' '}
              <a key="mailLink" className="footer__column-links--other" href={`mailto:${frontmatter.e_mail}`}>
                {frontmatter.e_mail}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__bottom">
        <hr className="footer__bottom-divider" />
        <p className="footer__bottom-paragraph">
          © {new Date().getFullYear()}{' '}
          <a className="footer__column-links--other" href="https://uni.wroc.pl/">
            Uniwersytet Wrocławski
          </a>
          , wszelkie&nbsp;prawa&nbsp;zastrzeżone
        </p>
        <p className="footer__bottom-paragraph">
          <a className="footer__column-links--other" href="https://infovation.pl/">
            Realizacja infovation.pl
          </a>
        </p>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        address_line_1: PropTypes.string.isRequired,
        address_line_2: PropTypes.string.isRequired,
        phone_number_1: PropTypes.string.isRequired,
        phone_number_2: PropTypes.string.isRequired,
        e_mail: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

Footer.defaultProps = {
  siteTitle: '',
}

export default ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
          frontmatter {
            address_line_1
            address_line_2
            phone_number_1
            phone_number_2
            e_mail
          }
        }
      }
    `}
    render={(data) => <Footer data={data} siteTitle={siteTitle} />}
  />
)
