import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { FaFacebookSquare, FaTimes } from 'react-icons/fa'
import ListLink from '../ListLink'

import './sideDrawer.css'

const SideDrawer = ({ drawerClickHandler, show, data }) => {
  let drawerClass = 'side-drawer'
  if (show) {
    drawerClass = 'side-drawer open'
  }
  return (
    <nav className={drawerClass}>
      <FaTimes className="side-drawer__btn-close" onClick={drawerClickHandler} />
      <ul>
        <ListLink key="home" to="/" className="side-drawer__link--regular">
          Strona główna
        </ListLink>
        <li>
          <p>Jakich języków uczymy</p>
        </li>
        {data.GetLanguages.edges.map((edge) => (
          <ListLink className="side-drawer__link--language" key={edge.node.id} to={edge.node.fields.slug}>
            {edge.node.frontmatter.language}
          </ListLink>
        ))}
        <ListLink key="news" to="/aktualnosci" className="side-drawer__link--regular">
          Aktualności
        </ListLink>

        <ListLink key="schedule" to="/plan-zajec/" className="side-drawer__link--regular">
          Plan zajęć
        </ListLink>
        <ListLink key="contact" to="/kontakt/" className="side-drawer__link--regular">
          Kontakt
        </ListLink>
        <li key="fbHeader" className="side-drawer__link--regular">
          <a
            className="side-drawer__link--regular"
            key="fbHeaderLink"
            href="https://www.facebook.com/SzkolaJezykowAntycznychIOrientalnychUWr"
          >
            <FaFacebookSquare key="fbSideIcon" className="side-drawer__link--icon" /> <span>Znajdź nas na Facebooku</span>
          </a>
        </li>
        <li key="sideFaculty">
          <a href="http://wfil.uni.wroc.pl/" className="side-drawer__link--regular">
            Wydział Filologiczny
          </a>
        </li>
        <li key="sideInstitute">
          <a href="http://www.isksio.uni.wroc.pl/" className="side-drawer__link--regular">
            ISKSIO
          </a>
        </li>
        <li key="sidePhilology">
          <a href="http://www.indologia.uni.wroc.pl/" className="side-drawer__link--regular">
            Indologia
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default ({ drawerClickHandler, show }) => (
  <StaticQuery
    query={graphql`
      query {
        GetLanguages: allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___language] }
          filter: { frontmatter: { templateKey: { eq: "language-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                language
              }
            }
          }
        }
      }
    `}
    render={(data) => <SideDrawer drawerClickHandler={drawerClickHandler} show={show} data={data} />}
  />
)
