import React, { Component } from "react"
import { Link, navigate } from 'gatsby'
import { filter, includes, debounce, isEmpty } from 'lodash'

import './search.css'

export default class Search extends Component {
  constructor(props) {
    super(props)
    this.pages = []
    this.state = {
      query: '',
      results: [],
    }
  }

  getLink = node => {
    if (node.frontmatter.title === 'Kontakt') {
      return '/kontakt'
    }
    return node.fields.slug
  }

  getText = frontmatter => {
    if (frontmatter.templateKey === 'index-page') {
      return 'Strona główna'
    }
    return frontmatter.title || `Język ${frontmatter.language}`
  }

  getDescription = frontmatter => {
    if (frontmatter.title === 'Kontakt') {
      return ''
    }
     return frontmatter.description_part_1 || frontmatter.short_description || frontmatter.description
  }

  getPages = () => {
    if (!isEmpty(this.pages)) return this.pages
    let pages = this.props.searchIndexMain.edges.map(edge => {
      const { frontmatter } = edge.node
      let content = [
        'title',
        'short_description',
        'description',
        'date',
        'description_part_1',
        'description_part_2',
        'language',
        'e_mail',
        'phone_number_1',
        'phone_number_2',
        'address_line_1',
        'address_line_2'
      ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()

      return {
        content,
        description: this.getDescription(edge.node.frontmatter),
        templateKey: frontmatter.templateKey,
        thumbnail: frontmatter.image || frontmatter.picture,
        link: this.getLink(edge.node),
        label: this.getText(edge.node.frontmatter),
        id: edge.node.id,
      }
    })

    let scheduleContent = ""
    this.props.searchIndexSchedule.edges.map(edge => {
      const { frontmatter } = edge.node
      scheduleContent += [
        'title',
        'description',
        'language',
        'group',
        'room',
        'day',
        'time',
      ].map(a => frontmatter[a]).filter(Boolean).join(' ').toLowerCase()
    })
    const schedulePage = {
      content: scheduleContent,
      templateKey: 'schedule-page',
      link: '/plan-zajec',
      label: 'Plan Zajęć',
      id: 'planZajec',
    }
    this.pages = [...pages, schedulePage]
    return this.pages
  }

  search = (text, key) => {
    if(key == 'Enter') {
      const query = text.toLowerCase()
      if (query.length > 2) {
        const results = filter(this.getPages(), (page) => includes(page.content, query))
        navigate(`/szukaj?=${query}`, { state: { results: results }} )
      }
    }
  }

  searchDebounced = debounce(text => {
    if(text !== this.props.initialQuery && text !== this.props.initialQuery + ' ') {
      this.search(text, 'Enter')
    }
  }, 300)

  render() {
    if (this.props.initialQuery)
    {
      this.searchInput.value = this.props.initialQuery
      if (this.props.fire) {
        this.search(this.searchInput.value, 'Enter')
      }
    }
    return (
      <div className="search__results">
        <input ref={(input) => { this.searchInput = input; }} className="search__input" type="text" onChange={e => this.searchDebounced(e.target.value)} onKeyPress={e => this.search(e.target.value, e.key)} placeholder="Wyszukaj i wybierz (min. 3 znaki)" />
      </div>
    )
  }
}
