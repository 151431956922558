import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Helmet from 'react-helmet'
import Header from '../Header'
import Footer from '../Footer'
import Backdrop from '../Backdrop'
import SideDrawer from '../SideDrawer'

import './layout.css'

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false
    }
  }

  drawerToggleButtonHandler = () => {
    this.setState((prevState) => {
      return {menuOpen: !prevState.menuOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({ menuOpen: false })
  }


  render() {
    let backdrop;

    if (this.state.menuOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
      }
        render={data => (
          <div className="container">
            <Header drawerClickHandler={this.drawerToggleButtonHandler} siteTitle={data.site.siteMetadata.title} initialQuery={this.state.searchQuery} fireSearch={this.props.fireSearch} />
            <SideDrawer drawerClickHandler={this.drawerToggleButtonHandler} show={this.state.menuOpen} />
            {backdrop}
            <div className="container__inner">
              {this.props.children}
            </div>
            <Footer siteTitle={data.site.siteMetadata.headerTitle} />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
